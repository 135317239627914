import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TokensResponse, ValidateOtpResponse } from "pages/authentication/authenticationService";
import { LoanStatusType } from "pages/dashboard/home/dashboardService";
import { LeadProvider } from "pages/registration/registrationService";
import type { RootState } from "store";
import { addMinutesToCurrentDate } from "utilities/date";

export interface UserState {
  userName: string;
  accessToken: string;
  userId: string;
  applicationId: string | undefined;
  status: string | undefined;
  tokenValidationParams: TokenValidation;
  version: string;
  dealApplicationId: string | undefined;
  nhDealApplicationId: string | undefined;
  prospectId: string | undefined;
  applicationRoleType: string;
  isLogin: boolean;
  loanStatus?: LoanStatusType;
  leadProvider: LeadProvider;
  loanPurpose: string;
  nhApplicationStatus: string | undefined;
  hasPAL: boolean | undefined;
  isInitialDocUploaded: boolean | undefined;
  currentTooltipIndex: number;
  loanId: string | undefined;
}

interface TokenValidation {
  refreshToken: string;
  lastActiveTime: Date;
  tokenExpiration: Date;
}

interface ApplicationRoutingData {
  nhDealApplicationId: string;
  nhApplicationId: string;
  applicationRoleType: string;
  dealApplicationId: string;
  nhApplicationStatus: string;
  loanId?: string;
  hasPal?: boolean;
  prospectId?: string;
  loanPurpose: string;
}

const initialState: UserState = {
  userName: "",
  accessToken: "",
  userId: "",
  applicationId: "",
  status: "",
  tokenValidationParams: {
    refreshToken: "",
    lastActiveTime: new Date(0),
    tokenExpiration: new Date(0),
  },
  version: "",
  dealApplicationId: "",
  nhDealApplicationId: "",
  prospectId: "",
  applicationRoleType: "",
  isLogin: false,
  loanStatus: "Started",
  leadProvider: LeadProvider.Default,
  loanPurpose: "",
  nhApplicationStatus: undefined,
  hasPAL: false,
  isInitialDocUploaded: false,
  currentTooltipIndex: 0,
  loanId: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<ValidateOtpResponse>) => {
      const { name, accessToken, id, refreshToken, version } = action.payload;
      const dateTimeStamp = new Date();
      state.userName = name;
      state.accessToken = accessToken;
      state.userId = id;
      state.tokenValidationParams.refreshToken = refreshToken;
      state.tokenValidationParams.lastActiveTime = dateTimeStamp;
      state.tokenValidationParams.tokenExpiration = addMinutesToCurrentDate(dateTimeStamp, 10);
      state.version = version;
    },
    setLogout: () => initialState,
    setApplicationId: (state, action: PayloadAction<string>) => {
      state.applicationId = action.payload;
    },
    setNhDealApplicationId: (state, action: PayloadAction<string>) => {
      state.nhDealApplicationId = action.payload;
    },
    setNhApplicationStatus: (state, action: PayloadAction<string>) => {
      state.nhApplicationStatus = action.payload;
    },
    setTokens: (state, action: PayloadAction<TokensResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.tokenValidationParams.refreshToken = action.payload.refreshToken;
      state.tokenValidationParams.tokenExpiration = action.payload.tokenExpireTime;
      state.tokenValidationParams.lastActiveTime = new Date();
    },
    setLastActiveSession: (state, action: PayloadAction<Date>) => {
      state.tokenValidationParams.lastActiveTime = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setIsInitialDocUploaded: (state, action: PayloadAction<boolean>) => {
      state.isInitialDocUploaded = action.payload;
    },
    setLeadProvider: (state, action: PayloadAction<LeadProvider>) => {
      state.leadProvider = action.payload;
    },
    setCurrentTooltipIndex: (state, action: PayloadAction<number>) => {
      state.currentTooltipIndex = action.payload;
    },
    setApplicationRoutingData: (state, action: PayloadAction<ApplicationRoutingData>) => {
      const {
        nhDealApplicationId,
        nhApplicationId,
        applicationRoleType,
        dealApplicationId,
        nhApplicationStatus,
        loanId,
        hasPal,
        prospectId,
        loanPurpose,
      } = action.payload;
      state.nhDealApplicationId = nhDealApplicationId;
      state.applicationId = nhApplicationId;
      state.applicationRoleType = applicationRoleType;
      state.dealApplicationId = dealApplicationId;
      state.nhApplicationStatus = nhApplicationStatus;
      state.loanId = loanId;
      state.hasPAL = hasPal;
      state.prospectId = prospectId;
      state.loanPurpose = loanPurpose;
    },
  },
});

export const {
  setCredentials,
  setLogout,
  setApplicationId,
  setTokens,
  setLastActiveSession,
  setUserId,
  setIsLogin,
  setNhDealApplicationId,
  setLeadProvider,
  setApplicationRoutingData,
  setCurrentTooltipIndex,
  setNhApplicationStatus,
  setIsInitialDocUploaded,
} = authSlice.actions;

export const getCurrentUserName = (state: RootState): string => state.auth.userName;
export const getCurrentAccessToken = (state: RootState): string => state.auth.accessToken;
export const getCurrentUserId = (state: RootState): string => state.auth.userId;
export const getCurrentUserApplicationId = (state: RootState): string => state.auth.applicationId ?? "";
export const getCurrentUserApplicationStatus = (state: RootState): string => state.auth.status ?? "";
export const getTokenValidationParameters = (state: RootState): TokenValidation => state.auth.tokenValidationParams;
export const getVersion = (state: RootState): string => state.auth.version;
export const getUserId = (state: RootState): string => state.auth.userId;
export const getDealApplicationId = (state: RootState): string => state.auth.dealApplicationId ?? "";
export const getLoanId = (state: RootState): string => state.auth.loanId ?? "";
export const getProspectId = (state: RootState): string => state.auth.prospectId ?? "";
export const getUserRole = (state: RootState): string => state.auth.applicationRoleType ?? "";
export const getLoanPurpose = (state: RootState): string => state.auth.loanPurpose ?? "";
export const getLeadProvider = (state: RootState): string => state.auth.leadProvider;
export const getNhDealApplicationId = (state: RootState): string => state.auth.nhDealApplicationId ?? "";
export const getApplicationRoutingData = createSelector(
  (state: RootState) => state.auth,
  ({ applicationId, nhApplicationStatus, nhDealApplicationId }) => ({
    applicationId,
    nhApplicationStatus,
    nhDealApplicationId,
  })
);
export const getHasPAL = (state: RootState): boolean => state.auth.hasPAL ?? false;
export const getIsInitialDocUploaded = (state: RootState): boolean => state.auth.isInitialDocUploaded ?? false;
export const getCurrentTooltipIndex = (state: RootState): number => state.auth.currentTooltipIndex;

export default authSlice.reducer;
