import { SetStateAction, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import OtpInput from "react-otp-input";
import { z } from "zod";
import { Button } from "components/ui/button/Button";
import { otp } from "utilities/formSchemas";
import "./authentication.scss";
import { usePostDeviceAuthenticationMutation, usePostValidateDeviceAuthMutation } from "./authenticationService";
import { useDispatch, useSelector } from "react-redux";
import { getUserId, setCredentials } from "features/authSlice";
import { useRedirect } from "hooks/useRedirect";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { ErrorType } from "utilities/error";
import { InvalidOTPError, LoginError, OTPExpiredError } from "utilities/Constant";
import { getResendCodeDeviceId, setResendCodeDeviceId } from "pages/login/loginSlice";
import { useNavigate } from "react-router-dom";

interface FormData {
  otp: string;
}

const formSchema = z.object({ otp });

export default function RegistrationAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const deviceAuthId = useSelector(getResendCodeDeviceId);
  useRedirect(userId === "" && deviceAuthId === "", "/");

  const [sendOtpRequest, { data: sendOtpData, isSuccess: isSendOtpSuccess }] = usePostDeviceAuthenticationMutation();

  useEffect(() => {
    if (isSendOtpSuccess && sendOtpData) {
      dispatch(setResendCodeDeviceId(sendOtpData.id));
    }
  }, [isSendOtpSuccess, sendOtpData]);

  const [error, setError] = useState({ status: 0, code: "" });
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onSubmit", // Only validate on submit
    reValidateMode: "onSubmit", // Re-validate only on submit
  });

  const [sendRequest, { isSuccess, data, isLoading, isError: isOtpError }] = usePostValidateDeviceAuthMutation();

  const onSubmit: SubmitHandler<FormData> = (value) => {
    return sendRequest({
      otp: value.otp,
      deviceAuthId: deviceAuthId,
      userId: userId,
    })
      .unwrap()
      .catch((error: ErrorType) => {
        setError((prev) => ({
          ...prev,
          status: error.status,
          code: error.data.code.toLowerCase(),
        }));
      });
  };

  if (isSuccess && data) {
    dispatch(setCredentials(data));
    navigate("/my-loans", { replace: true });
  }

  let ErrorMessage = "";

  if (isOtpError && error.status === 400) {
    if (error.code === "invalid otp") {
      ErrorMessage = InvalidOTPError;
    } else {
      ErrorMessage = OTPExpiredError;
    }
  }

  const handleResendCode = () => {
    return sendOtpRequest(deviceAuthId);
  };

  if (isLoading) return <Overlay />;

  return (
    <div className="account">
      <form onSubmit={handleSubmit(onSubmit)} className="account-form">
        <div className="account-form__title">Please verify your account</div>
        <div className="account-form__digit">Enter your 6-digit code below</div>
        {isOtpError &&
          (error.status === 400 ? (
            <p className="error__message1">{ErrorMessage}</p>
          ) : (
            <div className="error__container">
              {LoginError.map((error) => (
                <p key={error} className="error__message2">
                  {error}
                </p>
              ))}
            </div>
          ))}
        {isSubmitted && errors.otp && <p className="error__message1">{errors.otp.message}</p>}
        <div>
          <Controller
            name="otp"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="account-form--input">
                <OtpInput
                  inputStyle="account-form--input-field"
                  onChange={(e: SetStateAction<string>) => {
                    onChange(e);
                    if (isSubmitted) {
                      clearErrors("otp");
                    }
                  }}
                  value={value}
                  numInputs={6}
                  isInputNum
                  separator={<span className="separator">&mdash;</span>}
                  containerStyle="account-form--container-field"
                />
              </div>
            )}
          />
        </div>
        <Button title="Continue" size="large" className="account-form__button1" />
      </form>
      <a onClick={() => handleResendCode()} className="link">
        Resend Code
      </a>
    </div>
  );
}
