import { useNavigate } from "react-router-dom";
import { ApplicationStatusNames } from "utilities/Constant";
import { useGetInterimDashboardQuery } from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getApplicationRoutingData, getNhDealApplicationId, getUserId } from "features/authSlice";

const usePerformRouting = () => {
  const navigate = useNavigate();
  const authRoutingData = useSelector(getApplicationRoutingData);
  const id = useSelector(getNhDealApplicationId);
  const userId = useSelector(getUserId);
  const { data: hasInterimDashboard, isSuccess } = useGetInterimDashboardQuery({id, userId});

  const getRouteForApplicationPath = (nhApplicationId?: string): string => {
    return nhApplicationId ? "/application" : "/path";
  };

  const performRouting = () => {
    const { nhApplicationStatus, applicationId } = authRoutingData;
    if ((nhApplicationStatus && nhApplicationStatus.toLowerCase() === ApplicationStatusNames.Completed.toLowerCase()) || hasInterimDashboard) {
        if (!location.pathname.startsWith("/dashboard")) {
          navigate("/dashboard", { replace: true });
        }
    } else {
      navigate(getRouteForApplicationPath(applicationId), { replace: true });
    }
  };

  return { isSuccess, performRouting };
};

export default usePerformRouting;
