import Modal from "components/modal/Modal";
import { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./documents.scss";
import { Button } from "components/ui/button/Button";
import FileDetails, { FileUploadedBy } from "components/file-details/FilesDetails";
import Card from "components/card/Card";
import useDesktopChecker from "hooks/useDesktopChecker";
import FileUploader from "components/file-uploader/FileUploader";
import { useSetStatusFlagOfNotificationMutation } from "pages/authentication/userService";
import {
  RetrieveDocumentsResponseDto,
  dashboardApi,
  useGetDashboardItemsQuery,
  useRetrieveDocumentsQuery,
  useRetrieveDocumentsForDealApplicationQuery,
  useUpdateDocumentIdsMutation,
  useUpdateDocumentIdsForDealApplicationMutation,
  useRetrievePalContentQuery,
  RetrievePalResponseDto,
} from "pages/dashboard/home/dashboardService";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentUserName,
  getCurrentUserApplicationId,
  setLogout,
  getDealApplicationId,
  getNhDealApplicationId,
  getLoanId,
  setIsInitialDocUploaded,
  getIsInitialDocUploaded,
  getCurrentUserId,
} from "features/authSlice";
import { useNavigate } from "react-router";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { showDocuments } from "utilities/UserversionFeatures";
import { Funded, PalInstanceQueryString, NotificationIdQueryString, LatestPal } from "utilities/Constant";
import { sortDocumentsByNaf } from "utilities/documents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import PreApprovalPreview from "../home/PalPreview/PreApprovalPreview";
import { useSearchParams } from "react-router-dom";

export default function Documents() {
  const navigate = useNavigate();
  if (!showDocuments()) {
    navigate("/");
  }
  const nhDealApplicationId = useSelector(getNhDealApplicationId);
  const { data } = useGetDashboardItemsQuery(nhDealApplicationId);
  const loanStatus = data?.loanStatus?.status;
  const isDesktop = useDesktopChecker();
  const [show, setShow] = useState(false);
  const [isPalPreview, setIsPalPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileInfo, setFileInfo] = useState({ filePath: "", fileName: "" });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const dealApplicationId = useSelector(getDealApplicationId);
  const loanId = useSelector(getLoanId);
  const userId = useSelector(getCurrentUserId);
  const [documentsDropdown, setDocumentsDropdown] = useState({ isNafDocumentsVisible: false, isUserDocumentsVisible: false });
  const [documentsByUser, setDocumentsByUser] = useState<RetrieveDocumentsResponseDto[]>([]);
  const [documentsByNaf, setDocumentsByNaf] = useState<RetrievePalResponseDto[]>([]);
  const [currentPalDocument, setCurrentPalDocument] = useState<RetrievePalResponseDto | undefined>(undefined);
  const [updateNotificationStatus] = useSetStatusFlagOfNotificationMutation();
  const {
    data: retrieveDocumentData,
    isLoading: isRetreiveDocumentLoading,
    isSuccess: isRetreiveDocumentSuccess,
  } = useRetrieveDocumentsQuery(applicationId, { skip: applicationId == "" });
  const {
    data: retrieveDocumentDealApplicationData,
    isLoading: isRetreiveDocumentDealAppLoading,
    isSuccess: isRetreiveDocumentDealAppSuccess,
  } = useRetrieveDocumentsForDealApplicationQuery(dealApplicationId, {
    skip: applicationId != "" || dealApplicationId == "",
  });
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  const {
    data: retrievePalData,
    isLoading: isRetreivePalLoading,
    isSuccess: isRetreivePalSuccess,
  } = useRetrievePalContentQuery(
    { nhDealApplicationId, loanId },
    {
      skip: !featureFlag?.preApprovalLetterEnabled || loanId === "",
    }
  );
  const [searchParams] = useSearchParams();
  const previewPalInstanceId = searchParams.get(PalInstanceQueryString);
  let recentPreviewPalInstanceId = previewPalInstanceId;
  if (recentPreviewPalInstanceId === LatestPal) {
    recentPreviewPalInstanceId = retrievePalData?.[0]?.palInstanceId ?? previewPalInstanceId;
  }
  const id = searchParams.get(NotificationIdQueryString)!;
  useEffect(() => {
    if (applicationId && !isRetreiveDocumentLoading && isRetreiveDocumentSuccess) {
      setDocumentsByUser(retrieveDocumentData);
    } else if (!applicationId && dealApplicationId && !isRetreiveDocumentDealAppLoading && isRetreiveDocumentDealAppSuccess) {
      setDocumentsByUser(retrieveDocumentDealApplicationData);
    }
  }, [
    applicationId,
    dealApplicationId,
    isRetreiveDocumentLoading,
    isRetreiveDocumentSuccess,
    isRetreiveDocumentDealAppLoading,
    isRetreiveDocumentDealAppSuccess,
    retrieveDocumentData,
    retrieveDocumentDealApplicationData,
  ]);

  useEffect(() => {
    if (loanId && !isRetreivePalLoading && isRetreivePalSuccess) {
      setDocumentsByNaf(retrievePalData);
      if (recentPreviewPalInstanceId) {
        const palInstanceDocument = retrievePalData.find((p) => p.palInstanceId == recentPreviewPalInstanceId);

        if (palInstanceDocument) {
          setCurrentPalDocument(palInstanceDocument);
          setIsPalPreview(true);
          void updateNotificationStatus(id);  
          navigate('/dashboard/documents');
        }
      }
    }
  }, [recentPreviewPalInstanceId, isRetreivePalLoading, isRetreivePalSuccess]);

  const toggleModal = () => setShow(!show);
  const [invokeDocumentContent, { isLoading: isDocumentLoading, isFetching: isDocumentFetching }] =
    dashboardApi.endpoints.retrieveDocumentContent.useLazyQuery();

  const handleClosePAL = () => {
    setIsPalPreview(false);
    setCurrentPalDocument(undefined);
  };
  const handleDocumentViewer = (path: string, name: string, documentRefId: string) => {
    if (documentRefId && retrievePalData && retrievePalData.findIndex((p) => p.palInstanceId == documentRefId) >= 0) {
      //It is Pal
      setCurrentPalDocument(retrievePalData.find((p) => p.palInstanceId == documentRefId));
      setIsPalPreview(true);
    } else {
      setFileInfo({ ...fileInfo, filePath: path, fileName: name });
      setShow(!show);
    }
  };
  const [display, setDisplay] = useState(false);
  const userName = useSelector(getCurrentUserName);
  const dispatch = useDispatch();
  const [UpdateDocumentIdsWithApplication] = useUpdateDocumentIdsMutation();
  const [UpdateDocumentIdsForDealApplication] = useUpdateDocumentIdsForDealApplicationMutation();
  const handleHide = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.type === "click" || event.type === "mousedown") {
      setDisplay(!display);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      setDisplay(!display);
    }
  };

  const handleByAction = async () => {
    if (display) {
      await UpdateDocumentsIds();
      setDisplay(!display);
    }
    setShowModal(!showModal);
  };
  const isInitialDocumentUploaded = useSelector(getIsInitialDocUploaded);
  const UpdateDocumentsIds = async () => {
    try {
      if (applicationId && userId) {
        await UpdateDocumentIdsWithApplication({
          applicationId,
          borrowerId: userId,
          documentUpdateDto: {
            isInitialDocumentUploaded: isInitialDocumentUploaded,
          },
        });
      } else if (dealApplicationId && userId) {
        await UpdateDocumentIdsForDealApplication({
          dealApplicationId,
          borrowerId: userId,
          documentUpdateDto: {
            isInitialDocumentUploaded: isInitialDocumentUploaded,
          },
        });
      }
      dispatch(setIsInitialDocUploaded(true));
    } catch (error) {
      console.error(`Error updating document id's`, error);
    }
  };
  const handleClick = () => setDisplay(!display);
  const docs = [{ uri: fileInfo.filePath, fileName: fileInfo.fileName }];
  const [fileUrl, setFileUrl] = useState<string>("");

  const getBlob = async (document: RetrieveDocumentsResponseDto) => {
    try {
      const { data, isError, error } = await invokeDocumentContent(document.fileId);
      if (isError && typeof error === "object" && "status" in error && error.status === 401) {
        dispatch(setLogout());
        navigate("/", { replace: true, state: { path: location.pathname } });
        return "";
      } else {
        if (data) {
          const blob = new Blob([Uint8Array.from(window.atob(data.fileContents), (c) => c.charCodeAt(0))], { type: data.contentType });

          const url = window.URL.createObjectURL(blob);
          setFileUrl(url); // Store the URL in state variable
          return url;
        } else {
          return "";
        }
      }
    } catch (error) {
      return "";
    }
  };
  const getPathUrl = (document: RetrieveDocumentsResponseDto, contentType?: string) => {
    if (document) {
      const blob = new Blob([Uint8Array.from(window.atob(document.documentPath), (c) => c.charCodeAt(0))], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      setFileUrl(url); // Store the URL in state variable
      return url;
    } else {
      return "";
    }
  };
  if (isRetreiveDocumentLoading || isRetreiveDocumentDealAppLoading || isRetreivePalLoading) {
    return <Overlay />;
  }

  if (isDocumentLoading || isDocumentFetching) {
    return <Overlay />;
  }

  return (
    <div className="documents-container">
      {isDesktop && <div className="documents-container__line" />}
      {isDesktop ? (
        <div className="documents-container__header">
          <p className="documents-container__title">Provided by NAF</p>
          <div className="documents-container__file-upload" onClick={handleHide} onMouseDown={handleHide} onKeyDown={handleKeyDown}>
            {loanStatus !== Funded && <Button title="Upload your file" icon={solid("cloud-arrow-up")} />}
          </div>
        </div>
      ) : (
        <div>
          <div className="documents-container__file-upload" onClick={handleHide} onMouseDown={handleHide} onKeyDown={handleKeyDown}>
            {loanStatus !== Funded && <Button title="Upload your file" icon={solid("cloud-arrow-up")} />}
          </div>
          {!isDesktop && <div className="documents-container__line" />}
          <div>
            <div className="documents-container__title_wrapper">
              <p className="documents-container__title"> Provided by NAF</p>
              {!isDesktop && documentsByNaf.length > 0 && (
                <div
                  className="chevron-arrow"
                  tabIndex={0}
                  onClick={() => setDocumentsDropdown((prev) => ({ ...prev, isNafDocumentsVisible: !prev.isNafDocumentsVisible }))}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      setDocumentsDropdown((prev) => ({ ...prev, isNafDocumentsVisible: !prev.isNafDocumentsVisible }));
                    }
                  }}
                >
                  <FontAwesomeIcon icon={documentsDropdown.isNafDocumentsVisible ? solid("chevron-up") : solid("chevron-down")} />
                </div>
              )}
            </div>
            {!isDesktop && (
              <p className={`documents-container__count${documentsDropdown.isNafDocumentsVisible ? "-active" : ""}`}>
                {documentsByNaf.length > 0 ? documentsByNaf.length.toString() + " documents" : "No documents available"}
              </p>
            )}
          </div>
        </div>
      )}

      {display && (
        <Modal
          customBody={<FileUploader handleByAction={handleByAction} onClick={handleClick} operationType="UploadDocuments" conditionId="" />}
          defaultHeader={false}
          defaultBody={false}
        />
      )}
      {showModal && <SuccessMessageModal handleByAction={handleByAction} />}
      <div>
        <DocumentCards
          documentsByUser={documentsByUser}
          documentsByNaf={sortDocumentsByNaf(documentsByNaf)}
          isDocumentsByNaf
          getPathUrl={getPathUrl}
          getBlob={getBlob}
          userName={userName}
          handleDocumentViewer={handleDocumentViewer}
          IsDropDownOn={documentsByNaf.length > 0 ? documentsDropdown.isNafDocumentsVisible : documentsDropdown.isUserDocumentsVisible}
        />
      </div>
      <div>
        <div>
          <div>
            <div className="documents-container__line" />
            <div>
              <div className="documents-container__title_wrapper">
                <p className="documents-container__title"> Provided by You</p>
                {!isDesktop && documentsByUser.length > 0 && (
                  <div
                    className="chevron-arrow"
                    tabIndex={0}
                    onClick={() => setDocumentsDropdown((prev) => ({ ...prev, isUserDocumentsVisible: !prev.isUserDocumentsVisible }))}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        setDocumentsDropdown((prev) => ({ ...prev, isUserDocumentsVisible: !prev.isUserDocumentsVisible }));
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={documentsDropdown.isUserDocumentsVisible ? solid("chevron-up") : solid("chevron-down")} />
                  </div>
                )}
              </div>
              {!isDesktop && (
                <p className={`documents-container__count${documentsDropdown.isUserDocumentsVisible ? "-active" : ""}`}>
                  {documentsByUser.length > 0 ? documentsByUser.length.toString() + " documents" : "No documents available"}
                </p>
              )}
            </div>
            <DocumentCards
              documentsByUser={documentsByUser}
              documentsByNaf={documentsByNaf}
              getPathUrl={getPathUrl}
              getBlob={getBlob}
              userName={userName}
              handleDocumentViewer={handleDocumentViewer}
              IsDropDownOn={documentsDropdown.isUserDocumentsVisible || documentsByUser.length === 0}
            />
          </div>
        </div>
      </div>
      {show && (
        <Modal
          defaultHeader
          defaultHeaderIcon={solid("pen-nib")}
          defaultHeaderName={fileInfo.fileName}
          defaultHandleHide={toggleModal}
          defaultBody={false}
          footerContentPosition="right"
          customBody={
            <>
              {fileInfo.filePath ? (
                <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
              ) : (
                <h1>Oops! There's a problem viewing this document.</h1>
              )}
            </>
          }
          footer={
            <>
              <Button title={"Close"} type={"round"} variant={"outline-primary"} onClick={toggleModal} />
              <a href={fileUrl} download={fileInfo.fileName} target="_blank" rel="noreferrer">
                <Button title={"Download"} type={"round"} variant={"primary"} />
              </a>
            </>
          }
        />
      )}
      {isPalPreview && <PreApprovalPreview onClose={handleClosePAL} palDocument={currentPalDocument} />}
    </div>
  );
}

interface SuccessMessageModalProps {
  handleByAction: () => void;
}

export const SuccessMessageModal = ({ handleByAction }: SuccessMessageModalProps) => {
  const handleClick = () => {
    handleByAction();
    if (location.pathname === "/dashboard/documents") {
      window.location.reload();
    }
  };
  return (
    <div className="success-message__container">
      <Modal
        defaultBody={false}
        defaultHeader
        defaultHandleHide={handleByAction}
        defaultHeaderName="Upload Files"
        defaultHeaderIcon={solid("cloud-arrow-up")}
        customBody={
          <div>
            <p className="message__primary">Your files have been successfully uploaded!</p>
            <p className="message__secondary">You can view uploaded documents in the Documents tab</p>
            <Button title="Return To Dashboard" onClick={handleClick} />
          </div>
        }
      />
    </div>
  );
};

interface DocumentCardsProps {
  documentsByUser: RetrieveDocumentsResponseDto[];
  userName: string;
  getPathUrl: (document: RetrieveDocumentsResponseDto, contentType?: string) => string;
  getBlob: (document: RetrieveDocumentsResponseDto) => Promise<string>;
  handleDocumentViewer: (path: string, name: string, documentRefId: string) => void;
  IsDropDownOn: boolean;
  documentsByNaf: RetrievePalResponseDto[];
  isDocumentsByNaf?: boolean;
}

const DocumentCards = ({
  documentsByUser,
  documentsByNaf,
  userName,
  getPathUrl,
  getBlob,
  handleDocumentViewer,
  IsDropDownOn,
  isDocumentsByNaf = false,
}: DocumentCardsProps) => {
  const isDesktop = useDesktopChecker();

  const transformDocuments = (documents: RetrievePalResponseDto[]): RetrieveDocumentsResponseDto[] => {
    return documents.map((doc: RetrievePalResponseDto) => ({
      fileId: doc.palInstanceId,
      name: doc.documentContent?.name ?? "",
      documentPath: doc.documentContent?.fileContents ?? "",
      dateCreated: doc.createdDate ?? "",
      category: "",
    }));
  };

  const documents = isDocumentsByNaf ? transformDocuments(documentsByNaf) : documentsByUser;

  return (
    <>
      {documents.length > 0 ? (
        <div className={isDesktop || (!isDesktop && IsDropDownOn) ? "documents-container__cards-active" : "documents-container__cards"}>
          {documents.map((document) => {
            return (
              <div
                className="documents-container__card"
                key={document.fileId}
                tabIndex={0}
                onClick={async () => {
                  handleDocumentViewer(
                    isDocumentsByNaf
                      ? getPathUrl(document, documentsByNaf.filter((doc) => doc.palInstanceId == document.fileId)[0].documentContent?.contentType)
                      : await getBlob(document),
                    document.name,
                    document.fileId
                  );
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    handleDocumentViewer(
                      isDocumentsByNaf
                        ? getPathUrl(document, documentsByNaf.filter((doc) => doc.palInstanceId == document.fileId)[0].documentContent?.contentType)
                        : await getBlob(document),
                      document.name,
                      document.fileId
                    );
                  }
                }}
              >
                <Card
                  body={<FileDetails fileName={document.name} />}
                  footer={<FileUploadedBy uploadedby={userName} createdDate={format(new Date(document.dateCreated), "MM/dd/yyyy  hh:mma")} />}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <p className="documents__placeholder">No documents available</p>
      )}
      {!isDesktop && <div className="documents-container__line" hidden={IsDropDownOn} />}
    </>
  );
};
