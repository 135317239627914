import { useEffect, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components/ui/badge/Badge";
import useDesktopChecker from "hooks/useDesktopChecker";
import "./table.scss";
import Modal from "components/modal/Modal";
import FileUploader from "components/file-uploader/FileUploader";
import {
  PendingItem,
  useGetConditionsQuery,
  useGetDashboardItemsQuery,
  useUpdateDocumentIdsForDealApplicationMutation,
  useUpdateDocumentIdsMutation,
} from "pages/dashboard/home/dashboardService";
import { SuccessMessageModal } from "pages/dashboard/documents/Documents";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserApplicationId,
  getIsInitialDocUploaded,
  getDealApplicationId,
  setIsInitialDocUploaded,
  getNhDealApplicationId,
  getUserId,
} from "features/authSlice";
import { showDocuments } from "utilities/UserversionFeatures";
import GuideToolTip from "components/ui/guideToolTip/GuideToolTip";
import { tooltipIndexValues } from "pages/dashboard/home/Dashboard";
import { AdditionalDocumentationCondition, DefaultConditionsCount } from "utilities/Constant";

export interface TableProps {
  readonly Tablevalues: PendingItem[];
  readonly info?: string;
  readonly showTooltip?: boolean;
  readonly setTooltipIndex?: (index: number) => void;
  readonly isStatusInStarted: boolean;
}

export default function TableItems({ Tablevalues, info, showTooltip, setTooltipIndex, isStatusInStarted }: TableProps) {
  const isDesktop = useDesktopChecker();
  const [show, setShow] = useState(false);
  const [currentConditionId, setCurrentConditionId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const applicationId = useSelector(getCurrentUserApplicationId);
  const dealApplicationId = useSelector(getDealApplicationId);
  const [UpdateDocumentIdsWithApplication] = useUpdateDocumentIdsMutation();
  const [UpdateDocumentIdsForDealApplication] = useUpdateDocumentIdsForDealApplicationMutation();
  const [showDelayedTooltip, setShowDelayedTooltip] = useState(false);
  const dispatch = useDispatch();

  if (showTooltip && !showDelayedTooltip) {
    setTimeout(() => {
      setShowDelayedTooltip(true);
    }, 3000);
  } else if (!showTooltip && showDelayedTooltip) {
    setShowDelayedTooltip(false);
  }
  const handleHide = (id: string) => {
    if (!showTooltip) {
      setShow(!show);
      setCurrentConditionId(id);
    }
  };

  const handleByAction = async () => {
    if (show) {
      await UpdateDocumentsIds();
      setShow(!show);
    }
    setShowModal(!showModal);
  };
  const userId = useSelector(getUserId);
  const isInitialDocUploaded = useSelector(getIsInitialDocUploaded);
  const UpdateDocumentsIds = async () => {
    try {
      if (applicationId && userId) {
        await UpdateDocumentIdsWithApplication({
          applicationId,
          borrowerId: userId,
          documentUpdateDto: {
            isInitialDocumentUploaded: isInitialDocUploaded,
          },
        });
      } else if (dealApplicationId && userId) {
        await UpdateDocumentIdsForDealApplication({
          dealApplicationId,
          borrowerId: userId,
          documentUpdateDto: {
            isInitialDocumentUploaded: isInitialDocUploaded,
          },
        });
      }
      dispatch(setIsInitialDocUploaded(true));
    } catch (error) {
      console.error(`Error updating document id's`, error);
    }
  };

  const handleClick = () => setShow(!show);
  const nhDealApplicationId = useSelector(getNhDealApplicationId);
  const { data } = useGetDashboardItemsQuery(nhDealApplicationId);
  const { data: value, isSuccess } = useGetConditionsQuery(nhDealApplicationId);
  const [Tablevalue, setTablevalues] = useState<PendingItem[]>([]);
  useEffect(() => {
    if (isSuccess && value) {
      setTablevalues(value);
    }
    setTablevalues(isStatusInStarted ? Tablevalues : Tablevalue.filter((obj) => obj.title == AdditionalDocumentationCondition));
  }, [isSuccess, data]);
  const loanstatus = data?.loanStatus.status != "Started";
  const tableItems = Tablevalue.length >= 1;

  return (
    <>
      <div className="table">
        <div className={loanstatus || Tablevalue.length == DefaultConditionsCount ? "table-content" : "table-items"}>
          {Tablevalue.map((item, index) => {
            return (
              <>
                <div
                  className={`table-items__main ${isDesktop && tableItems ? "item" : ""}  ${showDelayedTooltip && index === 0 ? "table-items__highlight" : ""
                    }`}
                  key={item.id}
                >
                  <div className="table-items__container">
                    <div className={showDocuments() ? "table-items__titles" : "table-items__titles--max_width"}>{item.title}</div>
                    {showDocuments() && (
                      <div className={`table-items__status ${showDelayedTooltip ? "table-items__status__tooltip--show" : ""}`}>
                        <Badge color={item.status == "Pending Approval" ? "pending-approval" : item.status.toLowerCase()} title={item.status} />
                      </div>
                    )}
                  </div>
                  {showDocuments() && (
                    <div
                      className={`table-items__action ${showDelayedTooltip ? "table-items__action__tooltip--show" : ""}`}
                      onClick={() => handleHide(item.id)}
                    >
                      {isDesktop ? (
                        <span>
                          <FontAwesomeIcon icon={solid("cloud-arrow-up")} />
                          &nbsp;
                          <span>{item.status != "Error" ? "Upload" : "Re-upload"}</span>
                        </span>
                      ) : (
                        <span>
                          <FontAwesomeIcon icon={solid("cloud-arrow-up")} size="lg" />
                        </span>
                      )}
                    </div>
                  )}
                  {show && currentConditionId == item.id && (
                    <Modal
                      customBody={
                        <FileUploader
                          handleByAction={handleByAction}
                          onClick={handleClick}
                          operationType="UploadDocumentsWithSpecificCondition"
                          conditionId={item.id}
                        />
                      }
                      defaultHeader={false}
                      defaultBody={false}
                    />
                  )}
                  {showModal && <SuccessMessageModal handleByAction={handleByAction} />}
                </div>
                {index === 0 && showDelayedTooltip && (
                  <div className="tooltip-popup">
                    <GuideToolTip
                      contentHeading="Pro Tip"
                      tooltipNo="1 of 3"
                      content="Upload requested documents for each task to
                      complete them."
                      cancelClick={() => {
                        setTooltipIndex?.(tooltipIndexValues.completed);
                      }}
                      buttonOnClick={() => {
                        setTooltipIndex?.(tooltipIndexValues.milestones);
                      }}
                      buttonName="Next"
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      {showDocuments() && showDelayedTooltip && <div className="transparent-overlay"></div>}
    </>
  );
}
